import React, { useState, useEffect, useRef } from "react";
import "./Dashboard.scss"; // Create a CSS file for styling
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../firebase"; // Assuming you have a Firebase configuration in this file
import { getAuth, signOut } from "firebase/auth";
import AccountCircleIcon from "@mui/icons-material/Person";
import "./Dashboarddark.scss";

const Dashboard = ({ selectedTab }) => {
  const column1Ref = useRef(null);
  const column2Ref = useRef(null);
  const [column1Data, setColumn1Data] = useState([]);
  const [column2Data, setColumn2Data] = useState([]);
  const [onNowRight, setOnNowRight] = useState([]);
  const [onNowLeft, setOnNowLeft] = useState([]);

  const [onLaterRight, setOnLaterRight] = useState([]);
  const [onLaterLeft, setOnLaterLeft] = useState([]);
  const [onNow, setOnNow] = useState([]);
  const [onLater, setOnLater] = useState([]);

  const [showW2W, setShowW2W] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(1);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (currentUser) => {
      if (!currentUser) {
        signOut(getAuth());
        window.localStorage.removeItem("user");
      }
      const userDocRef = doc(db, "users", currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      // Fetch data for Column 1 from Firestore
      const fetchColumn1Data = async (user) => {
        const column1CollectionRef = collection(db, "users"); // Replace with your Firestore collection reference
        const usersQuery = query(
          column1CollectionRef,
          where("agency", "==", user.agency)
        );
        const unsubscribeUsers = onSnapshot(usersQuery, (querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => doc.data());
          const users = [];
          data.forEach((ele) => {
            if (ele.OndutyEMS || ele.OndutyFire) {
              users.push(ele);
            }
          });
          setColumn1Data(users);
        });
      };

      const fetchUserData = async (userId) => {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);

        const userName = userDoc.data().name;
        const userImg = userDoc.data().userImg;

        return { userName, userImg };
      };

      // Fetch data for Column 2 from Firestore
      const fetchColumn2Data = async (user) => {
        const currentTime = new Date();
        const oneHourAgo = new Date(currentTime);
        oneHourAgo.setHours(currentTime.getHours() - 1);
        const alarmsCollection = collection(db, user.agency);
        const alarmsQuery = query(alarmsCollection);
        const unsubscribeAlarms = onSnapshot(
          alarmsQuery,
          async (querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => doc.data());
            const postsWithSeconds = data.filter(
              (post) => post.postTime && post.postTime.seconds
            );
      
            postsWithSeconds.sort((a, b) => {
              return b.postTime.seconds - a.postTime.seconds;
            });
      
            // Filter posts based on privacy values
            const postsWithAllowedPrivacy = postsWithSeconds.filter(post => {
              const privacy = post.privacy;
              return !privacy || privacy === "All" || privacy === "Stationboard";
            });
      
            // Use Promise.all to fetch user data for all posts asynchronously
            const postsWithUserData = await Promise.all(
              postsWithAllowedPrivacy.map(async (post) => {
                const userData = await fetchUserData(post.userId);
                return { ...post, ...userData };
              })
            );
      
            setColumn2Data(postsWithUserData);
            setLastUpdated(new Date());
          }
        );
      
        return () => {
          unsubscribeAlarms();
        };
      };
      
      const deptCollection = await getDocs(
        collection(db, userDoc.data()["agency"] + " Station Board")
      );
      deptCollection.forEach((dept) => {
        if (dept.data()["when2work"]) {
          setShowW2W(true);
          getShifts(dept.data()["apiKey"], dept.data()["endPoint"]);
          setInterval(() => {
            getShifts(dept.data()["apiKey"], dept.data()["endPoint"]);
    }, 5 * 60 * 1000);
        } else {
          setShowW2W(false);
        }
      });

      // Call the fetch functions to load data
      fetchColumn1Data(userDoc.data());
      fetchColumn2Data(userDoc.data());
    });
  }, []); // The empty dependency array ensures this code only runs once on component mount

  useEffect(() => {
    const scrollColumns = () => {
      if (column1Ref.current) {
        const column1 = column1Ref.current;

        // Check if column1 is scrollable
        const canScrollColumn1 = column1.scrollHeight > column1.clientHeight;

        // Scroll column1 based on the scroll direction
        if (canScrollColumn1) {
          column1.scrollTop += scrollDirection * 30; // Adjust the scrolling speed as needed
        }

        // Change scroll direction when reaching the top or bottom
        if (
          column1.scrollTop === 0 ||
          column1.scrollTop + column1.clientHeight >= column1.scrollHeight
        ) {
          setScrollDirection(scrollDirection === 1 ? -1 : 1);
        }
      }
    };

    const intervalId = setInterval(scrollColumns, 3000);
    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [scrollDirection]);

  function splitEventsByTime(events) {
    const currentTime = new Date(); // Get the current time
    const tomorrow = new Date(currentTime);
    tomorrow.setDate(currentTime.getDate() + 1); // Set the date for tomorrow

    const onToday = [];
    const onTomorrow = [];

    events.forEach((event) => {
      // Parse date and time strings
      const startDateParts = event.START_DATE.split("/");
      const endDateParts = event.END_DATE.split("/");
      const startTimeParts = event.START_TIME.match(/(\d+)(\w+)/);

      if (
        startDateParts.length === 3 &&
        endDateParts.length === 3 &&
        startTimeParts
      ) {
        const startMonth = parseInt(startDateParts[0]) - 1; // Months are zero-based
        const startDay = parseInt(startDateParts[1]);
        const startYear = parseInt(startDateParts[2]);

        const startHour = parseInt(startTimeParts[1]);
        const startAmPm = startTimeParts[2];

        const startDateTime = new Date(
          startYear,
          startMonth,
          startDay,
          startAmPm === "pm" ? startHour + 12 : startHour,
          0
        );

        if (
          startDateTime.getDate() === currentTime.getDate() &&
          startDateTime.getMonth() === currentTime.getMonth() &&
          startDateTime.getFullYear() === currentTime.getFullYear()
        ) {
          onToday.push(event);
        } else if (
          startDateTime.getDate() === tomorrow.getDate() &&
          startDateTime.getMonth() === tomorrow.getMonth() &&
          startDateTime.getFullYear() === tomorrow.getFullYear()
        ) {
          onTomorrow.push(event);
        }
      }
    });

    const onNowLeft = [];
    const onNowRight = [];
    const onLaterLeft = [];
    const onLaterRight = [];

    // Distributing today's events between left and right
    onToday.forEach((event, index) => {
      if (index % 2 === 0) {
        onNowLeft.push(event);
      } else {
        onNowRight.push(event);
      }
    });

    // Distributing tomorrow's events between left and right
    onTomorrow.forEach((event, index) => {
      if (index % 2 === 0) {
        onLaterLeft.push(event);
      } else {
        onLaterRight.push(event);
      }
    });

    setOnNowLeft(onNowLeft);
    setOnNowRight(onNowRight);
    setOnLaterLeft(onLaterLeft);
    setOnLaterRight(onLaterRight);
  }

  async function getShifts(api, endPoint) {
    const currentDate = new Date();

    // Extract year, month, and day
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = String(currentDate.getDate()).padStart(2, "0");

    // Extract year, month, and day for tomorrow
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);

    const yearTomorrow = tomorrow.getFullYear();
    const monthTomorrow = String(tomorrow.getMonth() + 1).padStart(2, "0");
    const dayTomorrow = String(tomorrow.getDate()).padStart(2, "0");

    // Format the date for tomorrow as "mm/dd/yyyy"
    const getDateTomorrow = `${monthTomorrow}/${dayTomorrow}/${yearTomorrow}`;
    console.log(
      "Fetching data from When2Work API for tomorrow:",
      getDateTomorrow
    );

    // Format the date as "mm/dd/yyyy"
    const getDate = `${month}/${day}/${year}`;
    console.log("Fetching data from When2Work API for date:", getDate);
    let resp = await fetch(
      endPoint + getDate + "&end_date=" + getDateTomorrow + "&key=" + api
    );
    console.log(resp);
    let data = await resp.json();
    console.log("Data from When2Work API:", data);
    splitEventsByTime(data.AssignedShiftList);
    setLastUpdated(new Date());
  }

  useEffect(() => {
    const checkDarkMode = () => {
      const currentHour = new Date().getHours();
      setIsDarkMode(currentHour < 8 || currentHour >= 17);
    };

    // Check dark mode on component mount
    checkDarkMode();

    // Check dark mode every minute
    const intervalId = setInterval(checkDarkMode, 60000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      className={`dashboard ${selectedTab === "map" ? "hide" : "show"} ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <div
        className={`dashboardColumn ${isDarkMode ? "dark-mode" : ""}`}
        ref={column1Ref}
      >
        <h2>Duty Crews</h2>
        <div className="cardList">
          {column1Data.map((item, index) => (
            <div className="dutycard" key={index}>
              Name: {item.name}
              <br></br>
              Position: {item.position}
              <br></br>
              On Duty Till: {item.onDutyTillstring}
            </div>
          ))}
          <div style={{ width: "100%", border: "solid 1px black" }}></div>
          {showW2W ? (
            <span>
              <h2>W2W:</h2>

              <h2>On Today</h2>
              {lastUpdated && <p>Last Updated: {lastUpdated.toLocaleString()}</p>}
              <div className="columnList">
                <div className="cardList">
                  {onNowLeft.map((item, index) => (
                    <div className="matching-card" key={index}>
                      <span className="cardName">
                        <AccountCircleIcon className="Icon" />
                        {item.FIRST_NAME} {item.LAST_NAME}
                      </span>
                      {item.POSITION_NAME} {item.CATEGORY_NAME}
                      <br></br>
                      Shift: {item.START_DATE} {item.START_TIME} -{" "}
                      {item.END_DATE} {item.END_TIME}
                    </div>
                  ))}
                </div>
                <div className="cardList">
                  {onNowRight.map((item, index) => (
                    <div className="matching-card" key={index}>
                      <div className="cardName">
                        <AccountCircleIcon className="Icon" />
                        {item.FIRST_NAME} {item.LAST_NAME}
                      </div>
                      {item.POSITION_NAME} {item.CATEGORY_NAME}
                      <br></br>
                      Shift: {item.START_DATE} {item.START_TIME} -{" "}
                      {item.END_DATE} {item.END_TIME}
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ width: "100%", border: "solid 1px black" }}></div>

              <h2>On Tomorrow</h2>
              {lastUpdated && <p>Last Updated: {lastUpdated.toLocaleString()}</p>}
              <div className="columnList">
                <div className="cardList">
                  {onLaterLeft.map((item, index) => (
                    <div className="matching-card" key={index}>
                      <span className="cardName">
                        <AccountCircleIcon className="Icon" />
                        <span className="NameText">
                          {" "}
                          {item.FIRST_NAME}
                        </span>{" "}
                        <span className="NameText">{item.LAST_NAME}</span>
                      </span>
                      {item.POSITION_NAME} {item.CATEGORY_NAME}
                      <br></br>
                      Shift: {item.START_DATE} {item.START_TIME} -{" "}
                      {item.END_DATE} {item.END_TIME}
                    </div>
                  ))}
                </div>
                <div className="cardList">
                  {onLaterRight.map((item, index) => (
                    <div className="matching-card" key={index}>
                      <div className="cardName">
                        <AccountCircleIcon className="Icon" />
                        <span className="NameText">
                          {" "}
                          {item.FIRST_NAME}
                        </span>{" "}
                        <span className="NameText">{item.LAST_NAME}</span>
                      </div>
                      {item.POSITION_NAME} {item.CATEGORY_NAME}
                      <br></br>
                      Shift: {item.START_DATE} {item.START_TIME} -{" "}
                      {item.END_DATE} {item.END_TIME}
                    </div>
                  ))}
                </div>
              </div>
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="dashboardColumn" ref={column2Ref}>
        <h2>Memos</h2>
        <div className="cardList">
          {column2Data.map((item, index) => (
            <div className="card" key={index}>
              <div className="cardName">
                {item.userImg ? (
                  <img
                    className="profilePhoto"
                    src={item.userImg}
                    alt="Profile"
                  />
                ) : (
                  <img
                    className="profilePhoto"
                    src="https://firebasestorage.googleapis.com/v0/b/ladderemsrn.appspot.com/o/photos%2Favatar.png?alt=media&token=8e349a8b-01d2-4e6b-bc9c-fc23482d2230"
                    alt="Default Profile"
                  />
                )}
                <span className="NameTextmemo">{item.userName}</span>
              </div>
              {item.post}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
