export const userColumns = [
  { field: "name", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.userImg} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "position",
    headerName: "Position",
    width: 100,
  },
  {
    field: "disabled",
    headerName: "Suspended",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.disabled}`}>
          {params.row.disabled}
        </div>
      );
    },
  },
];
