import { useEffect, useState } from "react";
import "./sidebar.scss";
import { auth, db } from "../../firebase";
import { doc, getDoc, collection, onSnapshot } from "firebase/firestore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./sidebardark.scss";

const Sidebar = () => {
  const [data, setData] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [weatherData, setWeatherData] = useState(null);
  const [forecastList, setForecastList] = useState(null);

  const [loading, setLoading] = useState(true);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [stationLogo, setStationLogo] = useState("");
  const [version, setVersion] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const updateDarkMode = () => {
      const hours = currentTime.getHours();
      setDarkMode(hours >= 17 || hours < 8); // Step 2
    };

    const intervalId = setInterval(updateDarkMode, 60 * 1000); // Update every minute
    updateDarkMode(); // Initial update

    return () => clearInterval(intervalId);
  }, [currentTime]);


  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const versionDocRef = doc(db, "Stationboards", "appversion");
        const versionDocSnapshot = await getDoc(versionDocRef);
        if (versionDocSnapshot.exists()) {
          const versionData = versionDocSnapshot.data();
          console.log("Fetched data from Stationboards:", versionData);
          if (versionData && versionData.Version) {
            if (!version) {
              // Initialize version state if it's empty
              setVersion(versionData.Version);
            } else if (versionData.Version > version) {
              setVersion(versionData.Version);
              window.location.reload(); // Reload page if fetched version is higher
            }
          }
        } else {
          console.log("Document does not exist!");
        }
      } catch (error) {
        console.error("Error fetching data from Stationboards:", error);
      }
    };

    fetchVersion(); // Fetch version once when the component mounts

    const intervalId = setInterval(fetchVersion, 12 * 60 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [version]);

  
  useEffect(() => {
    async function fetchWeatherData() {
      try {
        const apiKey = "50229c69ed23500083e0004c56b83be4"; // Replace with your OpenWeatherMap API key
        const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${currentLocation.lat}&lon=${currentLocation.lng}&appid=${apiKey}&units=imperial`;

        const response = await axios.get(apiUrl);
        setWeatherData(response.data);
        fetchWeatherHistory();
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    }
    async function fetchWeatherHistory() {
      try {
        const apiKey = "50229c69ed23500083e0004c56b83be4"; // Replace with your OpenWeatherMap API key
        const apiUrl = `https://api.openweathermap.org/data/2.5/forecast?lat=${currentLocation.lat}&lon=${currentLocation.lng}&appid=${apiKey}&units=imperial`;

        const response = await axios.get(apiUrl);
        const list = response.data.list;
        let forecast = [];

        list.forEach((ele) => {
          const date = ele.dt_txt.split(" ")[0];
          let found = false;
          for (let i = 0; i < forecast.length; i++) {
            if (forecast[i].dt_txt.split(" ")[0] == date) {
              found = true;
              break;
            }
          }
          if (!found) {
            forecast.push(ele);
          }
        });
        setForecastList(forecast);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    }

    if (currentLocation) {
      fetchWeatherData();
      setInterval(() => {
        fetchWeatherData();
      }, 60 * 60 * 1000); // one hour
    }
  }, [currentLocation]);

  useEffect(() => {
    auth.onAuthStateChanged(async (currentUser) => {
      const userDocRef = doc(db, "users", currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      // Fetch data for Column 1 from Firestore
      const fetchData = async (user) => {
        const column1CollectionRef = collection(
          db,
          user.agency + " Station Board"
        ); // Replace with your Firestore collection reference

        onSnapshot(column1CollectionRef, (querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => doc.data());
          setData(data[0]);
          const apiKey = "AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM";
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${data[0].zip}&key=${apiKey}`;
          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              if (data.status === "OK" && data.results.length > 0) {
                const location = data.results[0].geometry.location;
                const lat = location.lat;
                const lng = location.lng;

                setCurrentLocation({ lat, lng });
              } else {
                console.error("Geocoding failed for the provided zip code.");
              }
            });
        });
      };

      const fetchAgencySettings = async (user) => {
        const column1CollectionRef = collection(db, user.agency + " Settings"); // Replace with your Firestore collection reference

        onSnapshot(column1CollectionRef, (querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => doc.data());
          setStationLogo(data[0].logo);
        });
      };

      // Call the fetch functions to load data
      fetchData(userDoc.data());

      fetchAgencySettings(userDoc.data());
    });
  }, []);

  useEffect(() => {
    // This function will be called once when the component mounts
    const intervalId = setInterval(() => {
      // Update the current time every second
      setCurrentTime(new Date());
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  function getForecastElements() {
    let elements = [];
    for (let i = 1; i < 4; i++) {
      let date = new Date(forecastList[i].dt_txt);
      let dateEle = (
        <p className="day">
          {date.toLocaleDateString("en-us", { weekday: "long" })}
        </p>
      );
      let weatherEle = (
        <div className="weather">
          <img
            src={
              "http://openweathermap.org/img/w/" +
              forecastList[i].weather[0].icon +
              ".png"
            }
          />
          <p>
            {Math.round(forecastList[i].main.temp_max)}
            {"\u00b0"}
          </p>
          <p>
            {Math.round(forecastList[i].main.temp_min)}
            {"\u00b0"}
          </p>
        </div>
      );

      elements.push(
        <div className="weather-day">
          {dateEle}
          {weatherEle}
        </div>
      );
    }

    return elements;
  }
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  return !loading ? (
    <div className={`sidebar ${darkMode ? "dark-mode" : ""}`}>
      <div className="logout">
        <p
          onClick={() => {
            auth.signOut();
            window.localStorage.removeItem("user");
            navigate("/login");
          }}
        >
          Logout
        </p>
        <p>Version: {version}</p>
      </div>
      <div className="logo">
        <img src={stationLogo} />
      </div>
      <div className={`details ${darkMode ? "dark-mode" : ""}`}>
        <h2>{data.name}</h2>
        <h4>{data.description}</h4>
      </div>
      <div className="weather-container">
        <div className="temperature">
          <img
            src={
              "http://openweathermap.org/img/w/" +
              weatherData.weather[0].icon +
              ".png"
            }
            width="85px"
            height="85px"
          />{" "}
          <p>
            {Math.round(weatherData.main.temp)}
            {"\u00b0"}
          </p>
        </div>
        <div className="location">
          <p>{weatherData.name}</p>
        </div>
      </div>
      <div className="forecast-list">{getForecastElements()}</div>
      <div className={`time ${darkMode ? "dark-mode" : ""}`}>
        <p>
          {hours < 10 ? `0${hours}` : hours}:
          {minutes < 10 ? `0${minutes}` : minutes}
        </p>
        
      </div>
      <div className="date">
        <p>
          {currentTime.toLocaleString("en-us", {
            weekday: "short",
            month: "short",
            day: "numeric",
          })}
        </p>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Sidebar;
