import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  TrafficLayer,
} from "@react-google-maps/api";
import { db } from "../../firebase";
import { auth } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  Timestamp,
  doc,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import FloatingMapCard from "../FloatingMapCard/floatingMapCard";
import alarmSound from "./fire_emergency.mp3";
import callmarker from "./call.png";
import callmarkerfire from "./firepin.png";
import callmarkerems from "./emspin.png";
import ScrollingText from "../ScrollingText/ScrollingText";
const showStyle = {
  display: "block",
};
const hideStyle = {
  display: "none",
};

const FullScreenMap = ({ alarms, setAlarms, selectedTab, setSelectedTab }) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [agency, setAgency] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [floatingMapCardText, setFloatingMapCardText] = useState(null);
  const [posts, setPosts] = useState([]);
  const [activePostIndex, setActivePostIndex] = useState(0);
  const [postText, setPostText] = useState("");
  //const [geocodingCache, setGeocodingCache] = useState({});
  const [currentMarkerIndex, setCurrentMarkerIndex] = useState(0);
  const [delayedAudio, setDelayedAudio] = useState(false);
  let geocodingCache = {};
  
  
  useEffect(() => {
    setDelayedAudio(true);

    const refreshInterval = setInterval(() => {
      setTimeout(() => {
        setDelayedAudio(false);
      }, 15000);

      window.location.reload();
    }, 60 * 60 * 1000); 

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);
  
  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const unsubscribe = onSnapshot(userDocRef, (doc) => {
          if (!doc.exists()) {
          } else {
            const dataObj = doc.data();

            setAgency(dataObj.agency);

            if (dataObj.disabled) {
              // Replace this with your navigation logic
            }
          }
        });

        return () => {
          unsubscribe();
        };
      }
    });
    return () => {
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    if (agency) {
      const currentTime = new Date();
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() - 1);
      const lastHourTimestamp = Timestamp.fromDate(oneHourAgo);

      // Create a Firestore query for the last 24 hours
      const alarmsCollection = collection(db, agency + " Alarms");
      const alarmsQuery = query(
        alarmsCollection,
        where("TimeStamp", ">=", lastHourTimestamp)
      );

      const unsubscribeAlarms = onSnapshot(alarmsQuery, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const data = change.doc.data();
          if (change.type === "added" && !delayedAudio) {
            const audio = new Audio(alarmSound);
            audio.play();
          }
        });

        const data = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        getMarkers(data);
      });

      const deptCollection = collection(db, agency + " Station Board");
      const deptQuery = query(deptCollection);
      const unsubscribeDept = onSnapshot(deptQuery, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        const apiKey = "AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM";
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${data[0].zip}&key=${apiKey}`;
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "OK" && data.results.length > 0) {
              const location = data.results[0].geometry.location;
              const lat = location.lat;
              const lng = location.lng;

              setCurrentLocation({ lat, lng });
            } else {
              console.error("Geocoding failed for the provided zip code.");
            }
          });
      });

      // Create a Firestore query for the last 24 hours

      const postsCollection = collection(db, agency);
      const postsQuery = query(
        postsCollection,
        where("privacy", "in", ["All", "Stationboard"])
    );
      const unsubscribePosts = onSnapshot(postsQuery, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        const postsWithSeconds = data.filter(
          (post) => post.postTime && post.postTime.seconds
        );

        postsWithSeconds.sort((a, b) => {
          return b.postTime.seconds - a.postTime.seconds;
        });
        let newPosts = postsWithSeconds.slice(0, 5);

        setPosts(newPosts);
      });

      return () => {
        unsubscribeAlarms();
        unsubscribeDept();
        unsubscribePosts();
      };
    }
  }, [agency]);

  function getMarkers(data) {
    for (let i = 0; i < data.length; i++) {
      const address = data[i].Address;
      const cleanedAddress = address.replace(/Address:/i, "").trim();
      const apiKey = "AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM";
      // Check if the result exists in the cache will not work if browser cache is disabled...
      if (geocodingCache[address]) {
        const cachedResult = geocodingCache[address];
        data[i].lat = cachedResult.lat;
        data[i].lng = cachedResult.lng;
      } else {
        // Perform the geocoding request and update the cache
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          cleanedAddress + ", " + data[i].Town + " United States"
        )}&key=${apiKey}`;

        fetch(apiUrl)
          .then((response) => response.json())
          .then((res) => {
            if (res.status === "OK" && res.results.length > 0) {
              const location = res.results[0].geometry.location;
              const latitude = location.lat;
              const longitude = location.lng;
              data[i].lat = latitude;
              data[i].lng = longitude;
              const prevCache = geocodingCache;
              // Update the geocoding cache
              geocodingCache = {
                ...prevCache,
                [address]: { lat: latitude, lng: longitude },
              };
            } else {
              console.error("Geocoding request failed:", res.status);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
    setAlarms(data);
  }

  useEffect(() => {
    let currentIndex = -1;

    const timer = setInterval(async () => {
      if (alarms.length > 0) {
        await checkAlarms();

        // Calculate the index of the next active marker
        currentIndex = (currentIndex + 1) % alarms.length;

        // Set the active marker's position as the new current location
        setCurrentLocation(alarms[currentIndex]);

        // Update the floating map card text with the corresponding alarm
        setCurrentMarkerIndex(currentIndex);

        setFloatingMapCardText(alarms[currentIndex]);
      } else {
        setFloatingMapCardText(null);
        setCurrentMarkerIndex(0);
      }

      if (alarms.length == 0) {
        geocodingCache = {};
        setSelectedTab("dashboard");
      }
    }, 12000);

    return () => {
      clearInterval(timer);
    };
  }, [alarms]);

  useEffect(() => {
    getPosts();
    const timer = setInterval(async () => {
      getPosts();
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, [posts]);

  async function getPosts() {
    let currentIndex = -1;
    if (posts.length > 0) {
      currentIndex = (currentIndex + 1) % posts.length;
      setActivePostIndex(currentIndex);

      const documentSnapshot = await getDoc(
        doc(db, "users", posts[currentIndex].userId)
      );

      setPostText(
        posts[currentIndex].post + " -- " + documentSnapshot.data().name
      );
    } else {
      setPostText("");
      setActivePostIndex(-1);
    }
  }

  function checkAlarms() {
    return new Promise((resolve, reject) => {
      var newAlarms = alarms;
      for (let i = 0; i < newAlarms.length; i++) {
        let marker = newAlarms[i];
        let time = marker.TimeStamp;
        let date = time.toDate();
        let now = new Date();

        const timeDifference = Math.abs(date - now);

        // Calculate the number of milliseconds in an hour
        const millisecondsInAnHour = 60 * 60 * 1000;
        if (timeDifference > millisecondsInAnHour) {
          newAlarms.splice(i, 1);
        }
      }
      setAlarms(newAlarms);
      resolve();
    });
  }
  const containerStyle = {
    width: "100vw",
    height: "100vh",
    display: selectedTab == "map" ? "block" : "none",
  };

  const mapOptions = {
    mapTypeId: "hybrid",
    trafficLayer: true,
  };

  const getMarkerIcon = (callcard) => {
    switch (callcard) {
      case "FIRE":
        return callmarkerfire;
      case "EMS":
        return callmarkerems;
      default:
        return callmarker;
    }
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={currentLocation}
        zoom={18}
        options={mapOptions}
      >
        <TrafficLayer autoRefresh />
        <FloatingMapCard text={floatingMapCardText} />
        {posts.length > 0 ? <ScrollingText text={postText} /> : <></>}

        {alarms.map((alarm, index) => (
          <Marker
            key={alarm.id} // Assuming each alarm has a unique 'id' property
            position={{ lat: alarm.lat, lng: alarm.lng }} // Use actual latitude and longitude properties from your alarm data
            title={`Alarm ${index + 1}`}
            icon={{
              url: getMarkerIcon(alarm.callcard),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default FullScreenMap;