import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useState } from "react";

const Navbar = ({ onMapButtonClick, onDashboardButtonClick }) => {
  const { dispatch } = useContext(DarkModeContext);
  const [activeTab, setActiveTab] = useState("map");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "map") {
      onMapButtonClick();
    } else if (tab === "dashboard") {
      onDashboardButtonClick();
    }
  };
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon />
        </div>
        <div className="items">
          <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div>
          <div
            className={`item ${activeTab === "map" ? "active" : ""}`}
            onClick={() => handleTabClick("map")}
          >
            Map
          </div>
          <div
            className={`item ${activeTab === "dashboard" ? "active" : ""}`}
            onClick={() => handleTabClick("dashboard")}
          >
            Dashboard
          </div>
          <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/ladderemsrn.appspot.com/o/photos%2F6D2A03B0-7BDC-4E2E-AC3E-A25FA346C0951689348252529.jpg?alt=media&token=1651cf04-8328-4bf4-96cd-ad142d9a8dd2"
              alt=""
              className="avatar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
