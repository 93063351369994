import React from "react";

const FloatingMapCard = ({ text }) => {
  let cardTextElement = null;
  if (text) {
    cardTextElement = (
      <div
        style={{
          color: "white",
          backgroundColor: "navy",
          padding: "15px",
          borderRadius: "0 0 8px 8px",
          textAlign: "left",
        }}
      >
        <p style={{ fontSize: "20px" }}>{text.CallType}</p>
        
        <p style={{ fontSize: "20px", color: "yellow" }}>{text.DispatchCode}</p>
        <br></br>
        <p style={{ fontSize: "20px" }}>{text.Address}</p>
        
        <p style={{ fontSize: "20px" }}>{text.CrossStreet}</p>
        <br></br>
        <p style={{ fontSize: "18px" }}>{text.EventComments}</p>
      </div>
    );
  } else {
    cardTextElement = (
      <div
        style={{
          color: "white",
          backgroundColor: "navy",
          padding: "15px",
          borderRadius: "0 0 8px 8px",
          textAlign: "left",
        }}
      >
        <p>Stand By...Loading Call Data...</p>
      </div>
    );
  }
  return (
    <div
      style={{
        position: "absolute",
        top: "10%",
        left: "10px",
        zIndex: 1000,
        background: "red",
        borderRadius: "8px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
        maxWidth: "425px",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          color: "black",
          padding: "10px",
          borderRadius: "8px 8px 0 0",
          marginBottom: "10px",
          textAlign: "left",
          fontWeight: "bold",
        }}
      >
        Alarm Details:<br></br>
        
      </div>
      {cardTextElement}
    </div>
  );
};

export default FloatingMapCard;
