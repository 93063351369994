import { useContext, useEffect, useState } from "react";
import "./login.scss";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, requestPermission } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import logo from "../login/logo1.png";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const { dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();

    const userQuery = query(
      collection(db, "users"),
      where("email", "==", email)
    );

    getDocs(userQuery)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userData = userDoc.data();

          if (userData.stationboard) {
            return signInWithEmailAndPassword(auth, email, password);
          } else {
            throw new Error(
              "You don't have the required permissions to access this system. Contact support for more details."
            );
          }
        } else {
          throw new Error("Username or password is incorrect");
        }
      })
      .then((userCredential) => {
        dispatch({ type: "LOGIN", payload: userCredential.user });
        navigate("/");
      })
      .catch((error) => {
        // Check if the error is due to incorrect password
        if (error.code === "auth/wrong-password") {
          setError(new Error("Username or password is incorrect"));
        } else {
          setError(error);
        }
      });
  };
  return (
    <div className="login-page">
      <div className="img-container">
        {/* Place your image or any other content here */}
        <div className="img-text">Step Up Your Response</div>
      </div>
      <div className="login-container">
        <img src={logo} alt="Logo" className="login-logo" />
        <form onSubmit={handleLogin} className="login-form">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
          <div className="login-remember">
            <input type="checkbox" id="rememberMe" />
            <label htmlFor="rememberMe">Remember me</label>
          </div>
          <button type="submit" className="login-button">
            LOGIN
          </button>
          {error && <span className="login-error">{error.message}</span>}
        </form>
        <div className="warning">
          <span>* * * * * * * * W A R N I N G * * * * * * * *</span>
          This system is the property of Upper Fi 'Dba' Ladder Suite. It is for
          authorized use only. By using this system, all users acknowledge
          notice of, and agree to comply with, Ladder Suites Terms Of Service
          Policy (“TOS”). Unauthorized or improper use of this system may result
          in administrative disciplinary action, civil charges/criminal
          penalties, and/or other sanctions. By continuing to use this system
          you indicate your awareness of and consent to these terms and
          conditions of use.
          <br />
          LOG OFF IMMEDIATELY if you do not agree to the conditions stated in
          this warning.
        </div>
      </div>
    </div>
  );
};

export default Login;
