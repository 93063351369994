import "./table.scss";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { auth } from "../../firebase";
import EMS from "../../components/images/EMS.png";
import FIRE from "../../components/images/FIRE.png";
import HP from "../../components/images/HP.png";
import { collection, query, where, getDocs, Timestamp, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";



const List = () => {
  const [rows, setRows] = useState([]);
  const [agency, setAgency] = useState(null);

  useEffect(() => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userDocRef = doc(db, "users", currentUser.uid);
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (!doc.exists()) {
          console.log("No user data found!");
        } else {
          const dataObj = doc.data();
          setAgency(dataObj.agency);

          if (dataObj.disabled) {
            // Replace this with your navigation logic
            console.log("User is disabled");
          }
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    if (agency) {
      // Calculate the timestamp for 5 days ago
      const currentTime = new Date();
      const twentyFourHoursAgo = new Date(currentTime - 24 * 60 * 60 * 1000);
      const last24HoursTimestamp = Timestamp.fromDate(twentyFourHoursAgo);

      // Create a Firestore query for the last 24 hours
      const collectionName = agency + " Alarms";
      const alarmsCollection = collection(db, collectionName);
      const last24HoursQuery = query(
        alarmsCollection,
        where("TimeStamp", ">=", last24HoursTimestamp)
      );

      // Fetch data for the last 5 days
      const fetchData = async () => {
        try {
          const querySnapshot = await getDocs(last24HoursQuery);
          const data = querySnapshot.docs.map((doc) => doc.data());
          setRows(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [agency]);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          {/* ... Header row ... */}
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell className="tableCell">{row.id}</TableCell>
              <TableCell className="tableCell">
                {row.callcard === "EMS" ? (
                  <img src={EMS} alt="EMS" className="image" width="35" height="35" />
                ) : row.callcard === "FIRE" ? (
                  <img src={FIRE} alt="Fire" className="image" width="35" height="35" />
                ) : row.callcard === "**HIGH PRIORITY**" ? (
                  <img src={HP} alt="High Priority" className="image" width="35" height="35" />
                ) : (
                  row.callcard
                )}
              </TableCell>
              <TableCell className="tableCell">{row.CallType}</TableCell>
              <TableCell className="tableCell">{row.DispatchCode}</TableCell>
              <TableCell className="tableCell">{row.Address}</TableCell>
              <TableCell className="tableCell">{row.Eventtime}</TableCell>
              {/* ... Other cells ... */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
