import Sidebar from "../../components/sidebar/Sidebar";
import "./home.scss";
import FullScreenMap from "../../components/map/FullScreenMap";
import React, { useEffect, useState } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import { auth, db, onMessageListener } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
//import Notification from "../../components/notification/notification";

const Home = (props) => {
  const [selectedTab, setSelectedTab] = useState("map");
  const [alarms, setAlarms] = useState([]);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        const agency = userDoc.data().agency;
        const deptCollection = collection(db, agency + " Station Board");
        const snapshot = await getDocs(deptCollection);
        snapshot.forEach((item) => {
          let tokens = item.data().tokens || [];
          if (window.localStorage.getItem("token")) {
            if (!tokens.includes(window.localStorage.getItem("token"))) {
              tokens.push(window.localStorage.getItem("token"));
            }
          }
          console.log(item.id);
          updateDoc(doc(db, agency + " Station Board", item.id), {
            tokens: tokens,
          });
        });
      }
    });
    return () => {
      unsubscribeAuth();
    };
  }, []);
  useEffect(() => {
    // Check if alarms is not empty
    if (alarms.length > 0) {
      setSelectedTab("map");
    } else {
      setSelectedTab("dashboard");
    }
  }, [alarms]);

  return (
    <div className="home">
      <div className="homeContainer">
        <FullScreenMap
          alarms={alarms}
          setAlarms={setAlarms}
          onAlarmsChange={setAlarms}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <Dashboard selectedTab={selectedTab} />
        <Sidebar />
      </div>
    </div>
  );
};

export default Home;
