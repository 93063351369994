import React from "react";
import "./ScrollingText.css"; // Create a CSS file for styling

class ScrollingText extends React.Component {
  render() {
    return (
      <div className="scrolling-text-container">
        <div className="scrolling-text">
          <span>{this.props.text}</span>
        </div>
      </div>
    );
  }
}

export default ScrollingText;
